.App {
  display: inline-block;
  overflow: hidden;
  position: relative;
  height: 100vh;
  width: 100vw;
}

#backgroundimage {
  height: 100vh;
  width: auto;

  pointer-events: none;
  position: absolute;
  z-index: -1;
} 

.loader {
  position: absolute;
  /* z-index: 1; */
  width: 60%;
  max-width: 300px;
  height: auto;

  top: 20vh;
  left: 10vw;
}

.loadimage {
  width: 100%;
  height: auto;
}

.button-box {
  position: absolute;
  bottom: 0;
  left: 10%;
}

.response-button {
  padding: 10px;
  border: none;
  background: none;
}

.button-image {
  box-shadow: 5px 5px 5px grey;
  width: 25vw;
  max-width: 100px;
}

.button-image:hover {
  box-shadow: 7px 7px 7px grey;
}

#load1 {
  z-index: 12;
}

#intermission1 {
  z-index: 11;
}

#load2 {
  z-index: 10;
}

#intermission2 {
  z-index: 9;
}

#load3 {
  z-index: 8;
}

#qbox1 {
  z-index: 7;
}

#qbox2 {
  z-index: 6;
}

#qbox3 {
  z-index: 5;
}

#qbox4 {
  z-index: 4;
}

#movieposterdiv {
  position: absolute;
  right: 10%;
  bottom: 50%;
  width: 50px;
  z-index: 0;
  display: none;
  animation: fadeIn 3s
}

#movieposter {
  width: 100%;
}

#spotifydiv {
  position: absolute;
  left: 30%;
  bottom: 30%;
  width: 50px;
  z-index: 0;
  display: block;
}

#spotify {
  width: 100%;
}

#actualposterdiv {
  position: absolute;
  top: 3%;
  left: 2%;
  width: 80%;
  z-index: 20;
  display: none;
  animation: fadeIn 3s
}

#actualposter {
  width: 100%;
}

#curlvideodiv {
  position: absolute;
  z-index: 20;
  width: 70%;
  left: 5%;
  top: 1%;
  display: none;
  animation: fadeIn 3s;
}

.inframeback {
  bottom: 10%;
}

.inframebackbutton {
  background-color: darkgrey;
  color: white;
  border-radius: none;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@media only screen and (max-width: 500px) {
  .button-image {
    width: 15vw;
  }
}
